// This file is based on vendor/styles/theme-paper-material.scss
// The only change is the primary color

@import "../../vendor/styles/_appwork/include-material";
@import "../../vendor/styles/_theme/common";
@import "../../vendor/styles/_theme/libs";
@import "../../vendor/styles/_theme/uikit";

$primary-color: #1980bd;
$body-bg: #f5f5f5;


body {
  background: $body-bg;
}

.bg-body {
  background: $body-bg !important;
}

@include appwork-common-material-theme($primary-color);
@include appwork-libs-material-theme($primary-color);
@include appwork-uikit-material-theme($primary-color);

// Navbar

@include appwork-navbar-variant('.bg-navbar-theme', #fff, $color: $text-muted, $active-color: $body-color);

.layout-navbar {
  box-shadow: 0 0 8px rgba($black, 0.07), 0 0 3px rgba($black, 0.05);
}

// Sidenav

@include appwork-sidenav-variant('.bg-sidenav-theme', #fff, $color: $text-muted, $active-color: $body-color, $menu-bg: darken($body-bg, 2.5%));

.bg-sidenav-theme {
  .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon,
  .sidenav-inner > .sidenav-item > .sidenav-link:hover .sidenav-icon,
  .sidenav-inner > .sidenav-item > .sidenav-link:focus .sidenav-icon {
    color: $gray-300;
  }
  .sidenav-inner > .sidenav-item.active > .sidenav-link .sidenav-icon {
    color: $primary-color !important;
  }
  .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    color: $primary-color !important;
    background: none !important;
  }
}
.layout-sidenav {
  box-shadow: 0 0 8px rgba($black, 0.07), 0 0 3px rgba($black, 0.05);
}
.layout-sidenav-horizontal {
  box-shadow: 0 0 8px rgba($black, 0.07), 0 1px 0 rgba($black, 0.02) inset;
}

// Footer

@include appwork-footer-variant('.bg-footer-theme', #eee, $color: $text-muted, $active-color: $body-color);
